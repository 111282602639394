.topic-card {
  height: 77vh;
  color: black;
  background-color: white;

  padding: 32px 16px;
  border-radius: 8px;
}

.topic-card__img {
  width: 100%;
}
