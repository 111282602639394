.score-page {
  padding: 16px;
  height: 100vh;
}

.score-page__heading {
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-weight: bold;
  font-size: 24px;
}

.score-page__card {
  height: auto;
  background: linear-gradient(256.15deg, #faad4f 0%, #fb6964 98.59%);
}

.score-page__card-title {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.score-page__card-list {
  margin-top: 8px;
}

.score-page__card-item {
  min-height: 48px;
  margin-top: 2px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  padding-right: 16px;
}
