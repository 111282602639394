.lessons-category {
  margin-top: 32px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper > .card {
  flex: 0 0 auto;
}
