.gradient-button {
  font-size: 20px;
  padding: 15px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
  border: none;
  padding: 9px 17px;

  background-image: linear-gradient(
    to right,
    #fb6964 0%,
    #faad4f 51%,
    #fb6964 100%
  );
}

.gradient-button:disabled {
  opacity: 0.5;
}

.gradient-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.gradient-button-1:hover {
  background-position: right center;
}
