.quiz-page {
  padding: 16px;
  height: 100vh;
}

.quiz-page__heading {
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-weight: bold;
  font-size: 24px;
}

.quiz-page__form {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-evenly;
  font-size: 20px;
}

.quiz-page__title {
  font-size: 24px;
  line-height: 24px;
  padding: 0 8px;
}

.quiz-page__carousel {
  margin: 0;
}

.quiz-page__link {
  margin: 8px 0px;
}

.quiz-page__button:disabled {
  opacity: 0.5;
}

.quiz-page__button {
  border: none;
  padding: 9px 17px;

  background: #faad4f;
  color: white;
  font-size: 20px;
  width: 100%;

  border-radius: 6px;
}

.quiz-page__form > label > input {
  margin-right: 12px;
}
