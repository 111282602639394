.content {
  margin-top: 30px;
}

.welcome-box {
  padding: 0px 16px;
}

.welcome-quote {
  text-align: center;
}

.welcome-message {
  font-weight: lighter;
  color: #d1d5db;
}
