.result-page {
  padding: 16px;
  height: 100vh;
}

.result-page__card {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.result-page__button:disabled {
  opacity: 0.5;
}

.result-page__button {
  border: none;
  padding: 9px 17px;

  background: #faad4f;
  color: white;
  font-size: 20px;
  width: 100%;

  border-radius: 6px;
}

.result-page__button-outline {
  background: transparent;
  border: 1px solid white;
}
