.lesson__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;

  position: static;
  width: 163px;
  height: 84px;
  left: 173px;
  top: 0px;

  background: linear-gradient(256.15deg, #faad4f 0%, #fb6964 98.59%);
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  color: white;
}

.lesson__card-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}
